<template>
  <div
    class="shadow-card overflow-hidden relative rounded w-full"
    style="border: 1px solid #eff2fd"
  >
    <credit-card-3
      :card-type="type"
      :card-status="status"
      :name="user.name"
      :credit-balance="creditBalance"
      :available-balance="availableBalance"
      :credit-limit="creditLimit"
      :amount-spent="creditBalance"
      :billing-date="nextBillingDate"
      class="w-full"
      @buttonClick="determineAction"
    />

    <div
      v-if="getFormError(pinRequestForm) || getFormError(activationForm)"
      class="absolute bg-white bg-opacity-75 grid inset-0 h-full p-5 place-items-center rounded w-full z-10"
    >
      <div class="alert alert-red-soft items-start w-full">
        <span class="alert-icon">!</span>
        <span>
          {{ getFormError(pinRequestForm) || getFormError(activationForm) }}
        </span>
      </div>
    </div>

    <template v-if="hasPin && !isActive">
      <paystack
        :amount="100"
        :callback="activate"
        type="button"
        class="hidden"
        :disabled="activationForm.loading"
        ref="addCardPaystack"
      >
        <ion-icon class="mr-2 text-xl" name="card-outline" />
        <sm-loader-white v-if="activationForm.loading" />
        <span v-else>Activate Card</span>
      </paystack>
    </template>

    <payment-credit-card-balance
      ref="balanceClearanceModal"
      :card="card"
      @get-personal-cards="emitGetPersonalCardsEvent"
    />
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    card: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      activationForm: this.$options.basicForm(),
      pinRequestForm: this.$options.basicForm(),
    };
  },
  computed: {
    availableCredit() {
      return this.card?.available_credit;
    },
    availableBalance() {
      return this.card?.available_balance;
    },
    cardTypeToUcFirst() {
      return this.type?.replace(/^\w/, (char) =>  char.toUpperCase());
    },
    creditBalance() {
      return (this.card?.available_balance || 0);
    },
    creditLimit() {
      return this.card?.credit_card_limit;
    },
    creditStatus() {
      if (this.creditUsed > 0) {
        return "clear_balance";
      }

      if (this.isActive) {
        return "activated";
      }

      if (this.activationForm.loading) {
        return "activating";
      }

      if (this.hasPin) {
        return "pin_requested";
      }

      if (this.pinRequestForm.loading) {
        return "pin_requesting";
      }

      return "ready";
    },
    creditUsed() {
      return Math.abs(this.card?.available_balance || 0);
    },
    hasPin() {
      return this.card?.pin_status || this.pinRequestForm.success;
    },
    isActive() {
      return this.card?.status === "active";
    },
    isCreditCard() {
      return this.type === "credit";
    },
    isDebitCard() {
      return this.type === "debit";
    },
    nextBillingDate() {
      const billingDay = this.user?.profile?.billing_date;
      if (!billingDay) {
        return null;
      }        
      const date = new Date();
      if(date.getDate() > billingDay) {
        date.setMonth(date.getMonth() + 1);          
      }
      date.setDate(billingDay);
      return date.format('MM dd, Y');
    },
    status() {
      return this.creditStatus;
    },
    type() {
      return this.card?.type;
    },
  },
  methods: {
    clearBalance() {
      this.repyamentError = {};
      this.$refs.balanceClearanceModal.open();
    },
    determineAction() {
      switch (this.status) {
        case "activated":
          break;
        case "activating":
          break;
        case "pin_requested":
          //this.googleEvent('Card Activation', 'activate_card');
          this.$refs.addCardPaystack.$el.click();
          //this.step = 'two'
          break;
        case "pin_requesting":
          break;
        case "ready":
          this.requestPin();
          break;
        case "requested":
          break;
        case "not_requested":
          // this.beginCardRequest();
          break;
        case "clear_balance":
          this.clearBalance();
          break;
        case "request_debit_card":
          this.beginDebitCardRequest();
          break;
        default:
          // statements_def
          break;
      }
    },
    emitGetPersonalCardsEvent() {
      this.$emit('get-personal-cards');
    },
    async activate(data) {
      var { reference } = data;
      this.activationForm.setLoading();

      await this.sendRequest("personal.cards.personal.activate", {
        data: {
          personal_card_accounts_id: this.user.personal_account.id,
          reference,
        },
        success: () => {
          this.activationForm.success = true;
          this.$success({
            title: `${this.cardTypeToUcFirst} Card Activated`,
            body: 'You can now use your credit card. Enjoy!',
            button: 'Yay! Thanks CredPal.'
          });
          this.$emit('activate-card');
        },
        error: (error) => {
          this.activationForm.error = error;
        },
      });

      this.activationForm.setLoading(false);
    },
    async requestPin() {
      this.pinRequestForm.setLoading();

      await this.sendRequest('personal.cards.personal.requestPin', {
        data: {
          personal_card_accounts_id: this.user.personal_account.id,
        },
        success: () => {
          this.googleEvent("Card Activation", "request_pin");
          this.pinRequestForm.success = true;
          this.$emit('request-pin');
          this.$success({
            title: 'Card Pin Sent',
            body: 'A 4 digit default pin has been sent to your phone, please visit the nearest ATM to reset it to your desired pin.',
            button: 'Got it',
          });
        },
        error: (error) => {
          this.pinRequestForm.error = error;
        },
      });

      this.pinRequestForm.setLoading(false);
    },
  },
};
</script>
